<template>
  <label class="checkbox-label" :class="[ {'invalid': invalid }]" :for="name">
    <input :id="name" v-model="internalValue" type="checkbox" :name="name">
    <span class="checkbox-unchecked" :class="[ {'invalid': invalid }]"></span>
    <span class="checkbox-checked" :class="[ {'invalid': invalid }]">
      <check-icon size="12" class="text-white"></check-icon>
    </span>
    <span v-if="!isEmpty(title)" class="checkbox-title">{{ title }} <span class="text-gray">{{ optional ? $t('Input.optional') : '' }}</span></span>
    <div v-else class="ml-32">
      <slot></slot>
    </div>
    <error-message :label="label" :invalid="invalid" :validation="validation" />
  </label>
</template>

<script>

import { CheckIcon } from 'vue-feather-icons';
import InputMixin from '@/mixins/InputMixin';
import ErrorMessage from '@/components/lib/InputElements/ErrorMessage';
import { isEmpty, isNil } from 'lodash/lang';

export default {
  name: 'Checkbox',
  components: {
    ErrorMessage,
    CheckIcon,
  },

  mixins: [InputMixin],
  props: {
    value: Boolean,
    title: String,
  },

  data: function () {
    return {
      internalValue: null,
    };
  },

  computed: {

  },

  watch: {
    value: function (newVal) {
      this.internalValue = newVal;
    },
    internalValue: function () {
      this.$emit('input', this.internalValue);
      this.$emit('input:value', this.internalValue);
    },
  },

  created: function () {
    this.internalValue = this.value;
  },

  mounted: function () {
    this.mounted = true;
  },

  methods: {
    isNil(arg) {
      return isNil(arg);
    },
    isEmpty(arg) {
      return isEmpty(arg);
    },
  },
};
</script>

<style lang="scss">

  .checkbox {
    @import '~@/styles/import';

    &-checked, &-unchecked {
      &.invalid {
        @apply border-error;
      }
    }

    &-label {
      &:focus-within {
        .checkbox {
          &-checked, &-unchecked {
            @apply border-primary;
          }
        }
      }

      a {
        @apply text-primary underline font-bold cursor-pointer;
      }
    }
  }
</style>
